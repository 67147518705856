import React from "react"
import PropTypes from "prop-types"
import { createGlobalStyle } from 'styled-components'
import "./theme.css"

const GlobalStyle = createGlobalStyle`
    :root {
       --modelBg: #e9e9e9;
       --heroBgColor: #F5F5F5;
       --white: #ebebeb;
       --black: #181818;
       --blue: #0094FF;    
    }

    body {
        margin: 0;
    }
    
    h1, h2 ,h3 {
        font-weight: 400;
    }
    
    h3 {
        margin-bottom: 0;
    }
    
    p {
        margin-bottom: 0;
    }
    `;


const Layout = ({ children }) => {

    return (
      <>
        <GlobalStyle/>
        <main>{children}</main>
      </>
  )
};

Layout.propTypes = {
  children: PropTypes.node.isRequired,
};

export default Layout
